<template>
  <div v-loading="loading">
    <div v-if="company">
      <ui-header :heading="company.name">
        <auth-checker :accessRoles="['SuperAdmin']">
          <ui-link
            type="text"
            :route="{ name: 'companies-edit', params: { id: company.id } }"
            >{{ `${$t('actions.edit')} ${$tc('models.company')}` }}</ui-link
          >
        </auth-checker>
      </ui-header>

      <building-list
        :heading="$tc('models.building', 2)"
        :buildings="buildings"
      >
        <template v-slot:actions>
          <ui-link
            type="text"
            :route="{
              name: 'companies-create-building',
              params: { companyId: company.id }
            }"
            >{{ `${$t('actions.add')} ${$tc('models.building')}` }}</ui-link
          >
        </template>
      </building-list>

      <!-- Card displaying option of exporting information -->

      <auth-checker :accessRoles="['SuperAdmin']">
        <ui-card
            :heading="`${$t('actions.export')}`"
            class="my-8"
        >
          <div class="export-container">
            <div class="flex-row">
              <el-date-picker
                  class="mb-4"
                  v-model="dateRange"
                  type="daterange"
                  range-separator="Til"
                  start-placeholder="Start"
                  end-placeholder="Slutt">
              </el-date-picker>
            </div>
            <div class="flex-row">
              <!-- Export student progress data -->
              <ui-button
                type="green"
                size="small"
                uppercase
                @click="exportUserProgressData"
              >
                {{`${$t('actions.export')} ${$t('models.users')}`}}
              </ui-button>

              <!-- Export user progress data -->
              <ui-button
                class="ml-2"
                type="green"
                size="small"
                uppercase
                @click="exportStudentProgressData"
              >
                {{`${$t('actions.export')} ${$tc('models.student', 2)}`}}
              </ui-button>
            </div>
          </div>

        </ui-card>
      </auth-checker>
    </div>
  </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader';
import UiLink from '@/components/ui/UiLink';
import UiCard from '@/components/ui/UiCard';
import UiButton from '@/components/ui/UiButton';
import BuildingList from '@/app/buildings/components/BuildingList';
import {downloadStudentDataExcel, downloadUserDataExcel, getCompany, getCompanyBuildings} from '../api';
import AuthChecker from '../../auth/components/AuthChecker';

export default {
  data() {
    return {
      company: null,
      buildings: [],
      loading: false,
      dateRange: null
    };
  },

  components: {
    UiHeader,
    UiLink,
    UiCard,
    UiButton,
    BuildingList,
    AuthChecker
  },

  methods: {
    async getCompany() {
      const company = await getCompany(this.$route.params.id);
      this.company = company;
    },

    async getBuildings() {
      this.loading = true;
      try {
        this.loading = false;
        const buildings = await getCompanyBuildings(this.$route.params.id);
        this.buildings = buildings.sort((a, b) => (a.name > b.name ? 1 : -1));
      } catch (err) {
        this.loading = false;
        const msg = {
          message: this.$t('errors.load', {
            msg: this.$tc('models.building', 2)
          }),
          type: 'error'
        };
        this.$message(msg);
      }
    },

    async exportStudentProgressData() {
      try {
        this.loading = true;
        await downloadStudentDataExcel(this.$route.params.id, this.formatDateTime(new Date(this.dateRange[0])), this.formatDateTime(new Date(this.dateRange[1])));
      } catch {
        this.$message({
          message: "Klarte ikke å eksportere student data",
          type: 'error'
        });
      } finally {
        this.loading = false;
      }
    },

    async exportUserProgressData() {
      try {
        this.loading = true;
        await downloadUserDataExcel(this.$route.params.id, this.formatDateTime(new Date(this.dateRange[0])), this.formatDateTime(new Date(this.dateRange[1])));
      } catch {
        this.$message({
          message: "Klarte ikke å eksportere bruker data",
          type: 'error'
        });
      } finally {
        this.loading = false;
      }
    },

    formatDateTime(date) {
      return date.toLocaleDateString("en-US");
    }
  },

  async created() {
    this.loading = true;
    await this.getCompany();
    await this.getBuildings();
    this.loading = false;
  },

  async mounted() {
    let company = await getCompany(this.$route.params.id);
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.companies", link: "/app/companies/"},
      {name: company.name, link: ""}
    ]);
  },
};
</script>

<style scoped>
.export-container {
  display: flex;
  flex-direction: column;
  margin-top: -15px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
</style>
